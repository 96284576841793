@import url('https://rsms.me/inter/inter-ui.css');
@import './variables.scss';
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Space+Mono&display=swap');
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e7e7e7;
  z-index: 100;
  -webkit-tap-highlight-color: rgba(#e7e7e7, 0);
  @media only screen and (min-device-width: 768px) {
    justify-content: center;
    align-items: center;
  }
}
