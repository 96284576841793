@import './variables.scss';

.app {
  // background-image: linear-gradient(to right, $lavendar-pink, $lavendar-purple);
  // background-image: url('assets/bg.png');
  background-size: cover;
  background-image: linear-gradient(to right, #e7e7e7, #e7e7e7);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  color: #e7e7e7;
  overflow: scroll;

  &__content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto;
  }

  &__header {
    padding: 4.125rem 0 0;
  }

  &__mobile-button-group {
    display: none;
  }

  &__spacing {
    flex: 1 0 auto;
    // margin-top: 4rem;
    max-height: 5rem;
    min-height: 2rem;
  }

  &__logo {
    width: 3rem;
    height: 3rem;
    //background-color: $white;
    margin-left: 4.4375rem;
    //border-radius: 50%;
  }

  &__top,
  &__bottom {
    flex: 0 0 auto;
    margin-left: 4.4375rem;
    margin-right: 13rem;
    display: flex;
    flex-flow: row nowrap;
  }

  &__top {
    // margin-bottom: 3.25rem;
  }

  &__bottom {
    margin-top: 3.5rem;
  }

  &__main-text-wrapper {
    flex: 1 0 auto;
    width: 0;
  }

  &__main-text {
    font-size: 2.25rem;
    font-weight: 500;
    color: #271F7C;
    line-height: 3.25rem;
    max-width: 40rem;
  }

  &__bottom {
    align-items: flex-end;
  }

  &__point {
    font-size: 1rem;
    font-family: Space Mono, monospace;
    line-height: 2.5rem;
    color: #271F7C;

    a {
      color: #271F7C;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .arrow {
    opacity: 0.4;
  }

  &__point + &__point {
    margin-top: 0.75rem;
  }

  &__footer {
    margin: 2rem 0 4.125rem 4.4375rem;
    font-family: 'Space Mono', monospace;
    //margin-left: 4.4375rem;
    //margin-bottom: 4.125rem;
  }

  &__special-text {
    color: #271F7C;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
    cursor: pointer;
  }

  &__special-text:hover {
    text-decoration: underline;
  }

  &__actions {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Space Mono', monospace;

    a {
      font-size: 1rem;
      line-height: 25rem;
      margin-left: 5rem;
      font-weight: 600;
      color: #271F7C;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    a + a {
      margin-left: 1.5rem;
    }
  }

  @media only screen and (max-width: 1080px) {
    &__top,
    &__bottom {
      margin-right: 3rem;
    }

    &__line {
      width: calc(100% - 17.375rem);
    }
  }

  @media only screen and (max-width: 910px) {
    &__main-text {
      font-size: 1.75rem;
      height: 9.75rem;
      max-height: fit-content;
      margin-top: 3rem;
      //width: 0;
    }

    &__point {
      font-size: 1rem;
      //line-height: 1rem;
    }
  }

  @media only screen and (max-width: 750px) {
    &__header {
      padding: 0;
    }

    &__spacing {
      display: none;
    }

    &__logo {
      margin: 1.5rem;
    }

    &__top {
      margin: 0;
      margin-top: 2rem;
      display: flex;
      flex-flow: column nowrap;

      button {
        display: none;
      }

      .app__main-text-wrapper {
        flex: 0 0 auto;
        width: 100%;
        padding: 0 1.5rem;
        box-sizing: border-box;
      }

      .app__main-text {
        font-size: 1.75rem;
        font-family: 'Rubik', sans-serif;
        line-height: 2.75rem;
        height: unset;
         max-height: fit-content;
        margin-top: 3rem;
      }
    }

    &__mobile-button-group {
      display: flex;
      flex-flow: row wrap;
      margin: 1rem auto 1rem;

      button {
        display: block;
        padding: 0.25rem 1.5rem;
        height: unset;
        min-width: unset;
        font-size: 0.75rem;

        &:nth-of-type(1) {
          margin-right: 0.5rem;
        }

        &:nth-of-type(2) {
          margin-left: 0.5rem;
        }
      }
    }

    &__bottom {
      // margin: 4.875rem 4rem 0 .625rem;
      margin: 1.5rem;
      margin-top: 3rem;

      button {
        // display: none;
      }

      .btn__primary {
        padding: 0;
        padding: 0.25rem 1rem;
        height: 2.5rem;
        font-size: 0.75rem;
        font-family: 'Rubik', sans-serif;
        min-width: 9rem;
        margin-right: 1.5rem;
      }

      .app__point {
        font-size: 1rem;
        font-family: Space Mono, monospace;
        line-height: 2rem;
        //padding: 0 1.5rem;
      }
    }

    &__footer {
      margin: 1rem;
      font-family: Space Mono,monospace;

    }

    &__actions a {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      margin-left: 1.5rem;
    }
    &__actions a + a {
      margin-left: 1rem;
    }

    &__special-text {
      font-size: 0.8rem;
      margin-left: 1.5rem;
      // display: none;
    }
  }
}

.btn {
  &__primary {
    min-width: 10.375rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    // padding: 1rem;
    height: 3rem;
    font-family: 'Rubik', sans-serif;
    border-radius: 15px;
    margin-right: 2rem;
    background-color: #E7E7E7;
    border: 1.5px solid #271F7C;
    color: #271F7C;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    outline: none;
    box-shadow: 0 0 16px 4px rgba(#271F7C, 0.1);
    transition: 200ms ease-in-out;

    &:hover {
      background-color: #271F7C;
      color: #E7E7E7;
      
    }

    &:active {
      background-color: #271F7C;
      color: #E7E7E7;
    }
  }
}

.text {
  &--bold {
    font-weight: 600;
    font-size: 18;
    color: #271F7C;
  }
}
