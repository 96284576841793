@import url(https://rsms.me/inter/inter-ui.css);
@import url(https://fonts.googleapis.com/css?family=Space+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Space+Mono&display=swap);
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

#root {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e7e7e7;
  z-index: 100;
  -webkit-tap-highlight-color: rgba(231, 231, 231, 0); }
  @media only screen and (min-device-width: 768px) {
    #root {
      justify-content: center;
      align-items: center; } }

.app {
  background-size: cover;
  background-image: -webkit-gradient(linear, left top, right top, from(#e7e7e7), to(#e7e7e7));
  background-image: -webkit-linear-gradient(left, #e7e7e7, #e7e7e7);
  background-image: linear-gradient(to right, #e7e7e7, #e7e7e7);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  color: #e7e7e7;
  overflow: scroll; }
  .app__content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto; }
  .app__header {
    padding: 4.125rem 0 0; }
  .app__mobile-button-group {
    display: none; }
  .app__spacing {
    flex: 1 0 auto;
    max-height: 5rem;
    min-height: 2rem; }
  .app__logo {
    width: 3rem;
    height: 3rem;
    margin-left: 4.4375rem; }
  .app__top, .app__bottom {
    flex: 0 0 auto;
    margin-left: 4.4375rem;
    margin-right: 13rem;
    display: flex;
    flex-flow: row nowrap; }
  .app__bottom {
    margin-top: 3.5rem; }
  .app__main-text-wrapper {
    flex: 1 0 auto;
    width: 0; }
  .app__main-text {
    font-size: 2.25rem;
    font-weight: 500;
    color: #271F7C;
    line-height: 3.25rem;
    max-width: 40rem; }
  .app__bottom {
    align-items: flex-end; }
  .app__point {
    font-size: 1rem;
    font-family: Space Mono, monospace;
    line-height: 2.5rem;
    color: #271F7C; }
    .app__point a {
      color: #271F7C;
      text-decoration: none; }
      .app__point a:hover {
        text-decoration: underline; }
  .app .arrow {
    opacity: 0.4; }
  .app__point + .app__point {
    margin-top: 0.75rem; }
  .app__footer {
    margin: 2rem 0 4.125rem 4.4375rem;
    font-family: 'Space Mono', monospace; }
  .app__special-text {
    color: #271F7C;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
    cursor: pointer; }
  .app__special-text:hover {
    text-decoration: underline; }
  .app__actions {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Space Mono', monospace; }
    .app__actions a {
      font-size: 1rem;
      line-height: 25rem;
      margin-left: 5rem;
      font-weight: 600;
      color: #271F7C;
      text-decoration: none; }
      .app__actions a:hover {
        text-decoration: underline; }
    .app__actions a + a {
      margin-left: 1.5rem; }
  @media only screen and (max-width: 1080px) {
    .app__top, .app__bottom {
      margin-right: 3rem; }
    .app__line {
      width: calc(100% - 17.375rem); } }
  @media only screen and (max-width: 910px) {
    .app__main-text {
      font-size: 1.75rem;
      height: 9.75rem;
      max-height: -webkit-fit-content;
      max-height: -moz-fit-content;
      max-height: fit-content;
      margin-top: 3rem; }
    .app__point {
      font-size: 1rem; } }
  @media only screen and (max-width: 750px) {
    .app__header {
      padding: 0; }
    .app__spacing {
      display: none; }
    .app__logo {
      margin: 1.5rem; }
    .app__top {
      margin: 0;
      margin-top: 2rem;
      display: flex;
      flex-flow: column nowrap; }
      .app__top button {
        display: none; }
      .app__top .app__main-text-wrapper {
        flex: 0 0 auto;
        width: 100%;
        padding: 0 1.5rem;
        box-sizing: border-box; }
      .app__top .app__main-text {
        font-size: 1.75rem;
        font-family: 'Rubik', sans-serif;
        line-height: 2.75rem;
        height: unset;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        margin-top: 3rem; }
    .app__mobile-button-group {
      display: flex;
      flex-flow: row wrap;
      margin: 1rem auto 1rem; }
      .app__mobile-button-group button {
        display: block;
        padding: 0.25rem 1.5rem;
        height: unset;
        min-width: unset;
        font-size: 0.75rem; }
        .app__mobile-button-group button:nth-of-type(1) {
          margin-right: 0.5rem; }
        .app__mobile-button-group button:nth-of-type(2) {
          margin-left: 0.5rem; }
    .app__bottom {
      margin: 1.5rem;
      margin-top: 3rem; }
      .app__bottom .btn__primary {
        padding: 0;
        padding: 0.25rem 1rem;
        height: 2.5rem;
        font-size: 0.75rem;
        font-family: 'Rubik', sans-serif;
        min-width: 9rem;
        margin-right: 1.5rem; }
      .app__bottom .app__point {
        font-size: 1rem;
        font-family: Space Mono, monospace;
        line-height: 2rem; }
    .app__footer {
      margin: 1rem;
      font-family: Space Mono,monospace; }
    .app__actions a {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      margin-left: 1.5rem; }
    .app__actions a + a {
      margin-left: 1rem; }
    .app__special-text {
      font-size: 0.8rem;
      margin-left: 1.5rem; } }

.btn__primary {
  min-width: 10.375rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  height: 3rem;
  font-family: 'Rubik', sans-serif;
  border-radius: 15px;
  margin-right: 2rem;
  background-color: #E7E7E7;
  border: 1.5px solid #271F7C;
  color: #271F7C;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  box-shadow: 0 0 16px 4px rgba(39, 31, 124, 0.1);
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out; }
  .btn__primary:hover {
    background-color: #271F7C;
    color: #E7E7E7; }
  .btn__primary:active {
    background-color: #271F7C;
    color: #E7E7E7; }

.text--bold {
  font-weight: 600;
  font-size: 18;
  color: #271F7C; }

